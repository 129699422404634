import React from 'react'
import CookieConsent from "react-cookie-consent";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

function Cookies () {
  return <CookieConsent
  containerClasses="py-2"
  location="bottom"
  buttonText="OK"
  // enableDeclineButton={true}
  // setDeclineCookie={false}
  onDecline={() => window.location = 'https://en.wikipedia.org/wiki/HTTP_cookie'}
  style={{ background: "#222", borderTop: 'solid #fff 1px' }}
  buttonStyle={{background:'deepskyblue', color: '#fff'}}
  expires={150}
>
  This website uses cookies to enhance the user experience.
  {" "}
  <br/>
  <span>
    Read more on our <Link to="/cookie-policy">Cookie policy page</Link>
  </span>
</CookieConsent>
}

export default injectIntl(Cookies)