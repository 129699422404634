import React from 'react'
import { injectIntl, Link } from "gatsby-plugin-intl"
import logo_white from '../../images/INMAGIK_WHITE.png'
import styles from './Footer.module.scss'
import { GoLaw, GoLink, GoOrganization, GoHome} from "react-icons/go";

function Footer({intl}){
  
  return <div className={`py-5 ${styles.footer}`}>
    <div className="container">

    <div className="row">
        <div className="col-md-3 p-3 text-md-left text-center">
          <div className="mb-2">
            <b><GoLink></GoLink> LINKS</b>
          </div>  
          <div className={styles.info}>
            <Link className="text-white" to={'/'}>Home</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/tech'}>Technology</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/services'}>Services</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/portfolio'}>Our work</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/opensource'}>Open source</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/blog'}>Blog</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/contact'}>Contact</Link>
          </div>      
        </div>
        <div className="col-md-3  p-3 text-md-left text-center">
          <div className="mb-2">
            <b><GoLaw></GoLaw> LEGAL</b>
          </div>
          <div className={styles.info}>
            <Link className="text-white" to={'/privacy-policy'}>Privacy policy</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/terms-of-use'}>Terms of use</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/copyright'}>Copyright notice</Link>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/cookie-policy'}>Cookie policy</Link>
          </div>      
        </div>
        
        <div className="col-md-3 p-3 text-md-left text-center">
          <div className="mb-2">
            <b><GoOrganization></GoOrganization> CONNECT</b>
          </div>          
          <div className={styles.info}>
            <a className="text-white" target="_blank" href={'mailto:info@inmagik.com'}>Mail: <b>info@inmagik.com</b></a>
          </div>      
          <div className={styles.info}>
            <a className="text-white" target="_blank" href={'https://twitter.com/inmagiklabs'}>Twitter</a>
          </div>      
          <div className={styles.info}>
            <a className="text-white" target="_blank" href={'https://github.com/inmagik'}>Github</a>
          </div>      
          <div className={styles.info}>
            <a className="text-white" target="_blank" href={'https://www.facebook.com/inmagik/'}>Facebook</a>
          </div>      
          <div className={styles.info}>
            <Link className="text-white" to={'/work-with-us'}>Work with us</Link>
          </div>      
          

        </div>
        <div className="col-md-3 p-3 text-md-left text-center">
          <div className="mb-2">
          <img src={logo_white} width="24" height="24" className="d-inline-block align-center mr-1" alt="inmagik logo"/>
          <b>INMAGIK srl </b>
          </div>
          <div className={styles.info}>
            Sede legale: via Sant'Orsola 2, 24122 Bergamo, Italy
          </div>
          <div className={styles.info}>
            Società a responsabilità limitata con socio unico,
            capitale sociale i.v. euro 10.000<br/>
            C.F. e P.IVA 03934600168<br/>
            Numero REA BG - 420856
          </div>
          
        </div>
        
    </div>
    </div>
    
    </div>

}

export default injectIntl(Footer)