import React from 'react';
import {changeLocale, injectIntl } from "gatsby-plugin-intl"
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const LanguageSwitcher = ({ intl, light }) => {
  return <UncontrolledButtonDropdown size="sm" >
  <DropdownToggle nav color='dark' className={light ? 'text-secondary' : 'text-secondary'} style={{width:78}}>
    <span className={`flag-icon flag-icon-${intl.locale === 'it' ? 'it' : 'gb'}`}></span> <span className="font-200">{intl.locale}</span>
  </DropdownToggle>
  <DropdownMenu right >
    <DropdownItem onClick={() => changeLocale('it')}><span className="flag-icon flag-icon-it"></span> it</DropdownItem>
    <DropdownItem onClick={() => changeLocale('en')}><span className="flag-icon flag-icon-gb"></span> en</DropdownItem>
  </DropdownMenu>
</UncontrolledButtonDropdown>
}

export default injectIntl(LanguageSwitcher)