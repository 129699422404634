import React, { useState, useEffect} from 'react'
// import { Link } from "gatsby"
import { Collapse, NavbarToggler } from "reactstrap"
import {Link, useIntl, FormattedMessage } from "gatsby-plugin-intl"
import LanguageSwitcher from './LanguageSwitcher';
import logo from '../../images/INMAGIK_COLOR.png'
import styles from './Header.module.scss'
import { GoThreeBars } from "react-icons/go";


export default function Header(){

  const [open, setOpen] = useState(false)
  const [light, setLight] = useState(false)
  const toggle = () => setOpen(!open)
  const intl = useIntl()

  const toggleLight = () => {    
    const top = window.scrollY
    const isLight = top > 200;
    setLight(isLight)
  }

  useEffect(()=>{
      window.addEventListener('scroll', toggleLight)

      return () =>  {window.removeEventListener('scroll', toggleLight)}
  }, [])

  return <nav className={`navbar sticky-top navbar-expand-lg animated-nav ${light ? 'bg-light navbar-light' : 'bg-black navbar-dark'} ${styles.navbar}`}>
      <div className="container-fluid">
      
      <Link className={`navbar-brand ${styles.brand}`} to="/">
        <img src={logo} width="30" height="30" className="d-inline-block align-top mr-3" alt="inmagik logo"/>
      </Link>

      <NavbarToggler onClick={toggle} 
      // tag={() => <GoThreeBars size="20px" />}
      />

      <Collapse isOpen={open} navbar>
        <div className="navbar-nav ml-auto align-items-center">
          {/* <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/services"
            partiallyActive={true}
          >
            About 
          </Link> */}
          <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/services"
            partiallyActive={true}
          >
            <FormattedMessage id="services">Services </FormattedMessage>
          </Link>
          <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/tech"
            partiallyActive={true}
          >
            <FormattedMessage id="technology">Technology </FormattedMessage>
          </Link>
          <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/portfolio"
            partiallyActive={true}
          >
            Portfolio
          </Link>
          {/* <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/products"
            partiallyActive={true}
          >
            Products
          </Link> */}
          <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/opensource"
            partiallyActive={true}
          >
            Open source
          </Link>
          <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/blog"
            partiallyActive={true}
          >
            Blog
          </Link>
          <Link
            className={`nav-item nav-link ${styles.link}`}
            activeClassName="active"
            to="/contact"
            partiallyActive={true}
          >
            <FormattedMessage id="contact">Contact</FormattedMessage>
          </Link>
        </div>
        <div className="text-center" style={{width: 'auto'}}>
        <LanguageSwitcher light={light}/>
        </div>
      </Collapse>
      
      
      {/* <span>
      
      </span> */}
    </div>
  </nav>
}